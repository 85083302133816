/* Layout - Imports */
import { headerLinkManager, headerScrollManager, headerMobileMenuManager } from './layout/header'
import { footer } from './layout/footer'
import { pageContent } from './layout/pageContent'
import { headerMenu } from './layout/headerMenu'
import { footerMenu } from './layout/footerMenu'

/* Elements - Imports */
import { carouselGalleryManager } from './elements/carouselGallery'
import { button } from './elements/button'

/* Components - Imports */
import { aboutUs } from './components/aboutUs'
import { contactMap } from './components/contactMap'
import { activityCalendar } from './components/activityCalendar'
import { foodList } from './components/foodList'
import { weAreKnownFor } from './components/weAreKnownFor'
import { topSlider } from './components/topSlider'

/* Page Templates - Imports */

/* Single Templates - Imports */
import { engageSingleActivityGallery } from './singleTemplates/single-activities'

/*
  -----------------------------------
  MAIN FILE
  -----------------------------------
*/
const elvtheme = (): void => {
  /* Layout */
  headerScrollManager()
  headerLinkManager()
  headerMobileMenuManager()
  headerMenu()
  footerMenu()
  footer()
  pageContent()

  /* Elements */
  carouselGalleryManager()
  button()

  /* Components */
  aboutUs()
  contactMap()
  activityCalendar()
  foodList()
  weAreKnownFor()
  topSlider()

  /* Page Templates */

  /* Single Templates */
  engageSingleActivityGallery()
}

export default elvtheme
